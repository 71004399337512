(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("ext/dhtmlxgantt_overlay", [], factory);
	else if(typeof exports === 'object')
		exports["ext/dhtmlxgantt_overlay"] = factory();
	else
		root["ext/dhtmlxgantt_overlay"] = factory();
})(window, function() {
return 